/* import __COLOCATED_TEMPLATE__ from './payment-failure-notification-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable promise/prefer-await-to-then */
import { computed, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Base from './base';
import moment from 'moment-timezone';

export default Base.extend({
  permissionsService: service(),
  customerService: service(),
  stripev3: service(),
  intercomEventService: service(),
  customer: alias('customerService.customer'),
  cardModalIsOpen: false,

  finalPaymentAttemptAt: computed('notificationData.final_attempt_at', function () {
    let finalPaymentAttemptAt = this.get('notificationData.final_attempt_at');

    if (!finalPaymentAttemptAt) {
      return null;
    }
    return moment(finalPaymentAttemptAt).startOf('day').toDate();
  }),

  nextPaymentAttemptAt: computed('notificationData.next_attempt_at', function () {
    let nextAttemptAt = this.get('notificationData.next_attempt_at');

    if (!nextAttemptAt) {
      return null;
    }
    return moment(nextAttemptAt).startOf('day').toDate();
  }),

  closeModal: action(function () {
    this.cardModalIsOpen = false;
    this.send('acknowledge');
  }),

  updatePaymentCard: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'update-card-button',
      context: 'payment-failure-notification',
    });

    let hasPermission = this.permissionsService.currentAdminCan('can_access_billing_settings');

    if (!hasPermission) {
      return this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_access_billing_settings',
      );
    }

    return this.stripev3
      .load()
      .then(() => {
        return this.customerService.refreshCustomer();
      })
      .then(() => {
        this.set('cardModalIsOpen', true);
        this.intercomEventService.trackAnalyticsEvent({
          action: 'viewed',
          object: 'update-card-modal',
          context: 'payment-failure-notification',
        });
      });
  }),
});
