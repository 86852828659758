/* import __COLOCATED_TEMPLATE__ from './server-side-hmac-generation-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { equal, gt, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  userSource: null,
  isSegment: equal('apiSecrets.firstObject.userSource', 'segment'),

  onChangeHMACGenerator: () => {},

  apiSecrets: null,
  multipleApiSecrets: gt('apiSecrets.length', 1),

  codeOptions: [
    { text: 'Rails (Ruby)', value: 'rails' },
    { text: 'Django (Python 3)', value: 'django' },
    { text: 'PHP', value: 'php' },
    { text: 'Node.js', value: 'node' },
    { text: 'Go', value: 'golang' },
    { text: 'Java', value: 'java' },
    { text: 'Other', value: 'other' },
  ],
  segmentCodeOptions: [
    { text: 'Rails (Ruby)', value: 'rails' },
    { text: 'Django (Python 3)', value: 'django' },
    { text: 'PHP', value: 'php' },
    { text: 'Node.js', value: 'node' },
    { text: 'Other', value: 'other' },
  ],
  language: null,
  languageIsOther: equal('language', 'other'),

  userHashBasedOn: null,
  isUserHashBasedOnEmail: equal('userHashBasedOn', 'email'),
  isUserHashBasedOnUserId: equal('userHashBasedOn', 'user_id'),

  changeHash: action(function (identifier) {
    this.set('userHashBasedOn', identifier);
    this.onChangeHMACGenerator(identifier);
  }),

  changeLanguage: action(function (language) {
    this.set('language', language);
  }),
});
