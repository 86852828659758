/* import __COLOCATED_TEMPLATE__ from './added-tags-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
export default Component.extend({
  tags: computed('addedTags', 'addedTag', function () {
    let tags = [];
    if (this.addedTag) {
      tags = [this.addedTag];
    } else {
      tags = this.addedTags;
    }
    return tags;
  }),
});
