/* import __COLOCATED_TEMPLATE__ from './cordova-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { A } from '@ember/array';
import { computed } from '@ember/object';
import Component from '@ember/component';
import SdkApp from 'embercom/models/sdk-app';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default Component.extend({
  app: null,
  androidSdkApp: null,
  iosSdkApp: null,
  fromOnboarding: false,
  language: null,
  intercomEventService: service(),

  secretKeys: computed('iosSdkApp.api_secret', 'androidSdkApp.api_secret', function () {
    return A([
      { userSource: 'iOS', key: this.get('iosSdkApp.api_secret') },
      { userSource: 'Android', key: this.get('androidSdkApp.api_secret') },
    ]);
  }),

  setSdkApps: task(function* () {
    let iOSSdkApp = yield SdkApp.findOrCreateForPlatform('iOS');
    let androidSdkApp = yield SdkApp.findOrCreateForPlatform('Android');
    this.set('iosSdkApp', iOSSdkApp);
    this.set('androidSdkApp', androidSdkApp);
  }).drop(),

  init() {
    this._super(...arguments);
    this.setSdkApps.perform();
  },

  didInsertElement() {
    this._super(...arguments);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'install_option_viewed',
      context: 'cordova',
      place: 'start_guide',
    });
  },
});
