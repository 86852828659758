/* import __COLOCATED_TEMPLATE__ from './google-tag-manager-component.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class GoogleTagManagerComponent extends Component {
  @service intercomEventService;

  @tracked app = this.args.app;
  @tracked pingDomains = this.args.pingDomains;
  @tracked disableToggle = this.args.pingDomains === null;
  @tracked fromOnboarding = this.args.fromOnboarding;

  constructor() {
    super(...arguments);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'install_option_viewed',
      context: 'google_tag_manager',
      place: 'start_guide',
    });
  }

  get secretKeys() {
    return [{ userSource: 'web', key: this.args.app.api_secret }];
  }
}
