/* import __COLOCATED_TEMPLATE__ from './complete-button.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
export default Component.extend({
  didReceiveAttrs() {
    this._super(...arguments);
    if (!this.completeAction) {
      throw new Error('Must pass completeAction to complete-button');
    }
  },
  label: null,
  processingLabel: null,
  completeAction: null,
  errorMessage: null,
  disabled: false,
});
