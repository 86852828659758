/* import __COLOCATED_TEMPLATE__ from './iframe-preview-component.hbs'; */
/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
/* eslint-disable jquery-unsafe/no-html */
import $ from 'jquery';
import { debounce, bind } from '@ember/runloop';
import { computed, observer } from '@ember/object';
import { on } from '@ember/object/evented';
import { readOnly, lte } from '@ember/object/computed';
import Component from '@ember/component';
import { ternary, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import renderTemplate from '@intercom/pulse/lib/render-template';
import ENV from 'embercom/config/environment';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default Component.extend({
  $iframe: null,
  MIN_IFRAME_HEIGHT: 437,
  htmlBlock: readOnly('blocks.firstObject'),
  usingCustomTemplate: readOnly('message.email_template.isCustom'),
  safeIframeContent: '',
  iframeContent: computed('htmlBlock.{header,content,footer}', function () {
    return `${this.htmlBlock.header}${this.htmlBlock.content}${this.htmlBlock.footer}`;
  }),
  unsubscribeText: ternary(
    'message.show_unsubscribe_link',
    '<a href="#" class="ic-unsubscribe-link">Unsubscribe from our emails</a>',
    '',
  ),
  iframeIsLTEMinHeight: lte('currentIframeHeight', 437),
  iframeHeight: ternaryToProperty(
    'iframeIsLTEMinHeight',
    'MIN_IFRAME_HEIGHT',
    'currentIframeHeight',
  ),
  iframeHeightStyle: computed('iframeHeight', function () {
    return `height: ${this.iframeHeight}px;`;
  }),
  mobileClassName: ternary('isMobilePreview', 'o__mobile', ''),
  iframeClassNames: computed('mobileClassName', function () {
    return `m__html-editor__preview${this.mobileClassName}`;
  }),

  initPreview: on('init', function () {
    this.iframeContent;
    this.setProperties({
      cachedHeight: this.MIN_IFRAME_HEIGHT,
    });
    this.sanitizeContent();
  }),

  willDestroyElement() {
    this.$iframe[0].removeEventListener('load', this.iframeLoadFunction);
    this.set('$iframe', null);
    this._super(...arguments);
  },

  formattedDate: computed(function () {
    return moment().format(dateAndTimeFormats.date);
  }),

  addIframeHeightToDom: observer({
    dependentKeys: ['iframeHeightStyle'],

    fn() {
      this.$iframe.attr('style', this.iframeHeightStyle);
    },

    sync: true,
  }),

  setSafeIframeContent: observer({
    dependentKeys: ['iframeContent'],

    fn() {
      debounce(this, this.sanitizeContent, ENV.APP._250MS);
    },

    sync: true,
  }),

  sanitizeContent() {
    if (!this.isDestroying) {
      let content = renderTemplate(this.iframeContent, {
        unsubscribe_link: this.unsubscribeText,
        unsubscribe_url: '',
      });
      this.set('safeIframeContent', sanitizeHtml(content, { config: 'email' }));
    }
  },

  createAndAddIframe: on(
    'didInsertElement',
    observer({
      dependentKeys: ['safeIframeContent', 'isMobilePreview'],

      fn() {
        if ($(this.element)) {
          this.createIframe();
          this.set('$iframe', $('iframe', this.element));
        }
      },

      sync: true,
    }),
  ),

  calculateHeightAndAddToDom() {
    let iframeHtmlElement = this.$iframe[0].contentDocument.documentElement;
    this.set('currentIframeHeight', $(iframeHtmlElement).height());
    this.$iframe.css('height', this.iframeHeight);
    this.set('cachedHeight', this.iframeHeight);
    this.addIframeHeightToDom();
    this.afterCreateIframe();
  },

  addIframeToDom(iframe) {
    // @pat - disabling sanitization here as we've already done this above and we're injecting the iframe node here
    /* eslint-disable-next-line jquery-unsafe/no-html */
    $('.js__iframe-container', this.element).html(iframe);
  },

  //TODO: Pat Pull this `srcdoc` polyfill out to its own lib https://github.com/intercom/intercom/issues/29043
  createIframe() {
    let iframe = document.createElement('iframe');
    iframe.style.cssText = this.iframeHeightStyle;
    iframe.className = this.iframeClassNames;
    iframe.style.height = `${this.cachedHeight}px`;
    iframe.style.minHeight = `${this.cachedHeight}px`;
    let iframeLoadFunction = bind(this, this.calculateHeightAndAddToDom);
    this.set('iframeLoadFunction', iframeLoadFunction);
    iframe.addEventListener('load', iframeLoadFunction);
    this.addContentToIframe(iframe);
    return iframe;
  },

  afterCreateIframe: $.noop,

  addContentToIframe(iframe) {
    iframe.srcdoc = this.safeIframeContent;
    this.addIframeToDom(iframe);
    return iframe;
  },
});
