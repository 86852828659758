/* import __COLOCATED_TEMPLATE__ from './identity-verification-ping-domains.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed, action } from '@ember/object';
import { readOnly, not, gt, none } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';

export default Component.extend({
  store: service(),
  appService: service(),
  intl: service(),
  intercomEventService: service(),
  pingDomains: null,
  errorMessage: null,
  secret: readOnly('appService.app.api_secret'),

  noDomainsHaveErrors: computed('pingDomains.@each.errorCode', function () {
    return this.pingDomains.isEvery('errorCode', null);
  }),

  domainsHaveErrors: not('noDomainsHaveErrors'),
  showPingDomains: gt('pingDomains.length', 0),

  showNoDataWarning: computed('pingDomains.@each.errorCode', function () {
    return this.pingDomains !== null && this.get('pingDomains.length') === 0;
  }),

  disableToggle: none('pingDomains'),

  checkPings: task(function* () {
    this.set('errorMessage', null);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'check_identity_installation',
      context: this.language,
      place: 'start_guide',
    });
    try {
      let pingDomains = yield this.store.query('ping-domain', { ignore_anonymous: true });
      this.set('pingDomains', pingDomains);

      if (this.showNoDataWarning) {
        this.set(
          'errorMessage',
          this.intl.t(
            'components.guide.start-screen.identity-verification-ping-domains.data-not-recieved',
          ),
        );
      }
    } catch (error) {
      this.set(
        'errorMessage',
        this.intl.t(
          'components.guide.start-screen.identity-verification-ping-domains.problem-in-checking',
        ),
      );
    }
  }).restartable(),

  openUserHashChecker: action(function () {
    this.set('showUserHashCheckerModal', true);
  }),

  closeUserHashChecker: action(function () {
    this.set('showUserHashCheckerModal', false);
  }),
});
