/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  notification: null,
  didDisplay: false,
  acknowledge: null,

  didRender() {
    this._super(...arguments);
    if (this.didDisplay) {
      return;
    }

    if (this.showNotification) {
      this.set('didDisplay', true);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'displayed',
        object: 'important-admin-notification',
        context: this.notification && this.notification.notification_type,
      });
    }
  },

  intercomEventService: service(),
  router: service(),

  notificationData: readOnly('notification.notification_data'),
  impersonationSession: readOnly('app.impersonation_session'),

  showNotification: computed('impersonationSession', 'notification.acknowledged_at', function () {
    return (
      !this.impersonationSession && this.notification && !this.get('notification.acknowledged_at')
    );
  }),

  actions: {
    acknowledge() {
      return this.acknowledge();
    },
  },
});
