/* import __COLOCATED_TEMPLATE__ from './important-admin-notification-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';

export default Component.extend({
  tagName: '',
  notifications: [],
  disabled: false,
  appService: service(),
  modelDataCacheService: service(),
  app: readOnly('appService.app'),
  notification: alias('notifications.firstObject'),

  isPaymentFailureNotificatonPresent: computed('notifications.[]', function () {
    return this.notifications?.any(function (notification) {
      return notification?.notification_type === 'payment_failure';
    });
  }),

  acknowledge: action(async function () {
    // We should remove the notification so they can dismiss it regardless of success
    let notification = this.notification;
    this.notifications.removeObject(this.notification);

    try {
      await ajax({
        url: '/ember/important_admin_notifications/acknowledge',
        type: 'POST',
        data: JSON.stringify({
          app_id: this.app.id,
          id: notification.id,
        }),
      });
      this.modelDataCacheService.clear();
    } catch (error) {
      captureException(error, {
        fingerprint: ['important-admin-notifications', 'failed-to-acknowledge'],
        extra: {
          notification,
        },
      });
      console.error('failed to acknowledge notification');
    }
  }),
});
