/* import __COLOCATED_TEMPLATE__ from './install-option-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

let iconUrls = {
  android: assetUrl('/assets/svgs/guide/start-screen/android.svg'),
  cordova: assetUrl('/assets/svgs/guide/start-screen/cordova.svg'),
  ios: assetUrl('/assets/svgs/guide/start-screen/ios.svg'),
  javascript: assetUrl('/assets/svgs/guide/start-screen/javascript.svg'),
  node: assetUrl('/assets/svgs/guide/start-screen/javascript.svg'),
  single_page: assetUrl('/assets/svgs/guide/start-screen/javascript.svg'),
  rails: assetUrl('/assets/svgs/guide/start-screen/rails.svg'),
  segment: assetUrl('/assets/svgs/guide/start-screen/segment.svg'),
  shopify: assetUrl('/assets/svgs/guide/start-screen/shopify.svg'),
  wordpress: assetUrl('/assets/svgs/guide/start-screen/wordpress.svg'),
  'mailchimp-sync': assetUrl('/assets/svgs/guide/start-screen/mailchimp.svg'),
  stripe: assetUrl('/assets/svgs/guide/start-screen/stripe.svg'),
  facebook: assetUrl('/assets/svgs/guide/start-screen/facebook.svg'),
  twitter: assetUrl('/assets/svgs/guide/start-screen/twitter.svg'),
};

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  key: null,
  name: null,
  description: null,
  type: null,
  installed: null,
  iconURL: computed('key', function () {
    return iconUrls[this.key];
  }),
  isIntegration: equal('type', 'integration'),
  showDescription: null,
});
