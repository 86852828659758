/* import __COLOCATED_TEMPLATE__ from './grouped-select-row-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
export default Component.extend({
  row: null,
  currentCoords: null,
  isSelected: computed('row', 'currentCoords', function () {
    let currentCoords = this.currentCoords;
    let rowCoords = this.get('row.coords');

    return currentCoords.row === rowCoords.row && currentCoords.group === rowCoords.group;
  }),

  actions: {
    select(coords) {
      this.action?.(coords);
    },
  },
});
