/* import __COLOCATED_TEMPLATE__ from './image-loader-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { run } from '@ember/runloop';
import Component from '@ember/component';

export default Component.extend({
  src: '',
  imageLoadingFailed: false,
  init() {
    this._super(...arguments);
    this.set(
      'markAsLoadingFailed',
      function () {
        run(() => {
          this.set('imageLoadingFailed', true);
        });
      }.bind(this),
    );
  },
  didInsertElement() {
    this._super(...arguments);
    this.element.addEventListener('error', this.markAsLoadingFailed, true);
  },
  willDestroyElement() {
    this.element.removeEventListener('error', this.markAsLoadingFailed, true);
    this._super(...arguments);
  },
});
